import "./App.css";

function App() {
  return (
    <div className="App">
      <h1 className="text-3xl font-bold">dj billy</h1>
      <li>
        <a href="https://twitter.com/blaing_">twitter</a>
      </li>
      <li>
        <a href="https://soundcloud.com/b-laing">soundcloud</a>
      </li>
    </div>
  );
}

export default App;
